// Main Color List - try not to use
$color__blue: #2b3a7c; // Kidderminster
$color__navy: #1a6eb7; // Kidderminster
$color__green: #c1d522; // Kidderminster
$color__green--2:#5d9c9c;
$color__green--3:#5d9c9c; // Carlisle college
$color__red: #e73037;
$color__red--2: #b9262c;
$color__teal: #a4e9ed;
$color__yellow: #ffda1a;
$color__purple: #60358b;
$color__green--dark: #6AC259;
$color__turquoise: #01a9da; // West Lancs
$color__turquoise--dark: #368f8c; // Carlisle College
$color__pink: #d83473; // West Lancs 
$color__pink--dark: #9e237e; // West Lancs 
$color__orange: #edbd03; // Kidderminster College

// Colors - Grayscale
$color__black: #000;
$color__black--93: #121212;
$color__black--91: #171717;
$color__black--90: #1a1a1a;
$color__black--89: #1c1c1c;
$color__black--85: #262626;
$color__black--80: #333;
$color__black--72: #474747;
$color__black--68: #535353;
$color__black--61: #636363;
$color__black--60: #666;
$color__black--50: #808080;
$color__black--49: #828282;
$color__black--40: #999;
$color__black--36: #ababab;
$color__black--35: #a6a6a6;
$color__black--30: #b3b3b3;
$color__black--25: #bfbfbf;
$color__black--22: #c7c7c7;
$color__black--20: #ccc;
$color__black--15: #d9d9d9;
$color__black--10: #e3e4e5;
$color__black--5: #f2f2f2;
$color__black--3-5: #f6f5f4;
$color__black--3: #f7f7f7;
$color__black--2: #fbfbfb;
$color__black--1: #f0f0f0;
$color__white: #fff;
$color__grey: #f5f5f5;
$color__grey--2: #ececec;
$color__grey--3: #464a51;  // Kiderminster college
$color__blue--light: #8e97ac;
$color__blue--light-2: #2393f6;
$color__blue--light-3: #e6f2fa; // Carlisle College

// Missing colors from HeroSubjectArea
$color__blue--very-dark: #1a2d59;
$color__blue--vivid: #2393f7;
$color__blue--strong: #005cb9;

// Colors by usage - use these!
$color__primary: $color__navy; //$color__blue;
$color__primary--light: $color__blue--vivid ;//$color__navy;
$color__primary--strong: $color__blue--strong;//$color__navy;
$color__secondary: $color__purple;
$color__tertiary: $color__green;
$color__variant--1: $color__green; // KC Adult 19+
$color__variant--2: $color__purple; // KC College 16-18
$color__variant--3: $color__pink; // KC Apprenticeships
$color__variant--4: $color__orange; // KC Higher Eduction
$color__variant--5: $color__grey; // Not in use WL
$color__uni--1: $color__red;
$color__uni--2: $color__red--2;
$color__dark-blue-2: $color__blue--very-dark;
$color__green-2: $color__green--2;


$color__background: $color__white;
$color__background--med: $color__grey--2;
$color__background--dark: $color__grey;
$color__text: $color__grey--3;
$color__text--reversed: $color__white;
$color__titles: $color__navy;
$color__separator: $color__black--85;
$color__placeholder: $color__black--20;
$color__border: $color__blue--light;
$color__caption: $color__black--1;
$color__form--valid: $color__green--dark;
$color__form--invalid: $color__red;

$color__facebook: #0e5ebd;
$color__twitter: #00aced;
$color__instagram: #192d59;
$color__youtube: #ff3333;

// West Lancs Study Areas
$color__16_18: $color__green;//old color $color__turquoise
$color__a_level: $color__turquoise--dark;
$color__adult: $color__navy;//old color $color__pink
$color__online: $color__pink--dark;
$color__apprenticeships: $color__pink;//old color $color__purple
$color__medicine: $color__green--2;
$color__employers: $color__blue--vivid;
$color__undergraduate: $color__orange;
