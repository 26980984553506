.grid-container {
  @include make-container();
  @include media-breakpoint-up(lg) {
    @include make-container-max-widths;
  }

  &.-grid-mobile {
    padding: 0;
    @include media-breakpoint-up(md) {
      @include make-container-max-widths;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}