.m-breadcrumb {
  @extend %f-body;

  li {
    display: inline-block;
    margin-right: 5px;
    list-style: none !important;
    margin-left: 0 !important;
  }

  li:not(:last-child)::after {
    content: '>';
    margin-left: 5px;
  }

  &.events-breadcrumb {
    margin: 30px 0;
  }
}