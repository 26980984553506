.m-stats {

  h2.title {
    color: $color__text--reversed;
    font-size: 1.75em;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
  }

}

.m-stats .m-card--stat {
  text-align: center;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid $color__border;

  @include media-breakpoint-up(md) {
    padding: 0 40px;
    margin-top: 0;
    border: none;
  }

  .stat-icon {
    max-width: 130px;
    height: auto;
  }

  h3 {
    @extend %f-h1;
    color: $color__secondary;
    font-size: 3.125rem;
    line-height: 3.125rem;
    font-weight: 600;
  }

  h4 {
    @extend %f-h4;
    color: $color__primary;
    text-transform: none;
    margin: 10px 0 0 0;
    font-size: 0.9375rem;
    //font-style: italic;
    line-height: 1.5em;
  }

  p {
    color: $color__text;
    margin-top: 15px;
  }

}

.m-stats .no-border .m-card--stat {
  border-bottom: none;
}

.m-stats .m-card--light {
  color: $color__text--reversed;

  h3 {
    color: $color__tertiary;
  }

  h4, .a-text-link, p {
    color: $color__text--reversed;
  }
}

.m-stats {
  .col-md-4 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .col-md-4:not(:last-child):not(.no-border) {
    @include media-breakpoint-up(md) {
      border-right: 1px solid $color__border;
    }
  }

  .col-md-4:last-child .m-card--stat {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.college-16-18 {
  .m-stats .m-card--stat h3 {
    color: $color__16_18;
  }
}

.university-centre {
  .m-stats .m-card--stat h3 {
    color: $color__variant--1;
  }
}

.undergraduate {
  .m-stats .m-card--stat h3 {
    color: $color__undergraduate;
  }
}

.international-students {
  .m-stats .m-card--stat h3 {
    color: $color__variant--4;
  }
}

.adult-19 {
    .m-stats .m-card--stat h3 {
        color: $color__adult;
    }
}

.apprenticeships {
  .m-stats .m-card--stat h3 {
    color: $color__apprenticeships;
  }
}

.employers {
    .m-stats .m-card--stat h3 {
        color: $color__employers;
        font-weight: 400;
    }
}

.contact--msg {
  line-height: 23px;
}

.stats-white .m-stats {
  h3, h4, p {
    color: $color--white !important;
  }
}

.stats-16-18 .m-stats {

  h3 {
    color: $color__16_18 !important;
  }

  h4,
  p {
    color: $color__white !important;
  }
}
