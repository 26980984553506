.m-hero {
	padding: 35px 0;
	width: 100%;
	background: $color__background--dark;

	@include media-breakpoint-up(md) {
		padding: 90px 0;
	}

  &.m-hero-padding-small {
    padding: 20px 0;

    @include media-breakpoint-up(md) {
      padding: 35px 0;
    }
  }

}

.m-hero--contact {
  background-size: cover;
  background-position: bottom;
}

.m-hero h1 {
	@extend %f-h1;
	color: $color__secondary;

	+ h2 {
		margin-top: 15px;
	}
}

.m-hero h2 {
	@extend %f-h4;
	color: $color__primary;

	+ h1 {
		margin-top: 10px;
	}
}

.m-hero h3 {
	@extend %f-h4;
}

.m-hero p {
	margin-top: 20px;

	&:first-child {
		margin-top: 0;
	}
}

.m-hero p,
.m-hero h2 {
  &:not(.o-textcolor--reversed, .no-shadow) {
    @include media-breakpoint-down(md) {
      //text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.9); // White shadow
    }
  }
  
  &.o-textcolor--reversed {
    @include media-breakpoint-down(md) {
      //text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4); // Dark shadow
    }
  }
}

.m-hero--error h1 {
  color: $color__secondary;
  margin-bottom: 10px;
}

.m-hero--error h2 {
  color: $color__primary;
}

.m-hero .a-media__img {
  margin: 30px auto 0 auto;
  display: block;
  padding: 0 60px;
  height: 100%;
  object-fit: cover;

  @include media-breakpoint-up(md) {
    margin: 0 auto;
    padding: 0;
  }
}

.m-hero .a-media--circle {
  max-width: 248px;
  max-height: 248px;

  @include media-breakpoint-up(md) {
  	width: 110px;
    height: 110px;
  }
}

.m-hero--search {
  text-align: left;
}

.m-hero-video--container {
  position: relative;
}

.m-hero-video--background {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  .m-hero {
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.m-hero--search .m-search {
	margin-top: 25px;
}

.m-hero--search h1 {
	@extend %f-h1-lg;
	color: $color__secondary;
}

.m-hero--search.m-hero--kidderminster-college {
  h1+h2 {
    font-size: 1.5em;
    margin-top: 1.5rem;
    font-weight: 900;
  }
}

.m-hero--search h2,
.m-search + .m-hero__content h2 {
	//@extend %f-h4;
    font-size: 1.35em;
	color: $color__text;
	text-transform: uppercase;
}

.m-hero--search .m-hero__content {
	margin-top: 34px;
}

.m-hero__links {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	flex-direction: column;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}
  
  li {
    text-align: center;
    margin-bottom: 0.5rem;
    
    @include media-breakpoint-up(lg) {
      text-align: left; 
    }
  }

	li + li {
		margin-top: 10px;

		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}
	}
}

.m-hero__links-item {
	@extend %a-text-link;
	@include f-h4();
	color: $color__text;
	text-decoration: none;
	margin: 0 22px;
	position: relative;
	display: inline-block;
  font-size: 1.15rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.3rem;
  }

	&::after {
		content: "";
		left: 0;
		right: 0;
		bottom: -5px;
		height: 4px;
		background: $color__primary;
		position: absolute;
		border-radius: 1px;
		transition: all 300ms ease;
		max-width: 0;
	}

	@include hover-supported() {
		&::after {
			max-width: 700px;
		}

		svg {
			color: $color__primary;
		}
	}
}

.m-hero--overlap {
  position: relative;
  padding: 32px 0 0 0;
  margin-bottom: 290px;

  @include media-breakpoint-up(md) {
    padding: 0 0;
    margin-bottom: 250px;
  }

  @include media-breakpoint-up(lg) {
    padding: 63px 0;
  }
  
  @include media-breakpoint-up(xl) {
    padding: 65px 0;
  }

  &.-overlap-no--margin {
    margin: 0 !important;
  }
  
  &.has-background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0%; 
      // This will show only the background colour of the image
    
    @include media-breakpoint-up(md) {
      background-position: 32%;
    }
    
    @include media-breakpoint-up(lg) {
      background-position: center center;
    }
    
  }
}

.m-hero--overlap-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;

  @include media-breakpoint-down(sm) {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 0 35px 0;
  }

  img {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.m-hero--overlap {
  .m-search {
	  margin-top: 75px;

    @include media-breakpoint-down(md) {
      margin-bottom: -50px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: -25px;
    }
  }
  
  // Kidderminster College
  &.m-hero--kidderminster-college {
    
    .m-search--wrapper {
      margin-top: 35px;
      
      > h2 {
        margin-bottom: 1rem;
      }
    }
    
    .m-search {
      margin-top: 0;
    }
  }
}

.m-hero--overlap {
  
  .m-hero__content {
  	padding-top: 30px;
    margin-bottom: -225px;
  
    @include media-breakpoint-down(sm) {
      margin-bottom: -260px;
      margin-top: 20px;
    }
  }
  
  // Kidderminster College
  &.m-hero--kidderminster-college {
    padding-top: 0;

    &.alt {
      &:after {
        content: '';
        background: url('/dist/images/hero-overlay.png') bottom left no-repeat;
        background-size: contain;
        z-index: 1;
        height: 90%;
        width: 55%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    
    > img.background-image {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      //filter: grayscale(100%) opacity(40%) brightness(135%);
    }
    
    > .grid-container {
      padding-top: 220px;
      position: relative;
      z-index: 2;
      
      @include media-breakpoint-up(xl) {
        padding-top: 9vw;
      }
      
      /*@media (min-width: 1800px){
        padding-top: 340px;
      }*/
    }
    
    // Has separate elements for shadows;
    .titles {
      
      $shadow-wrapper-h1: 0px 0px 200px rgba(0,0,0,1);//0px 0px 120px rgba(0,0,0,1);
      $shadow-wrapper-h2: 0px 0px 60px rgba(0,0,0,1);//0px 0px 40px rgba(0,0,0,1);
      
      .shadow-wrapper {
        display: inline-block;
        position: relative;
        /*margin-bottom: 4rem;*/
        
        > .absolute {
          position: absolute;
          top: 0;
          left: 0;
        }

        .separate-lines {
          word-spacing: 100vw;

          @include media-breakpoint-down(sm) {
               font-size: 2.5rem;
            }
        }
        
        // H1
        > h1,
        > .absolute > .h1 {
          font-size: 3rem;
          line-height: 3rem;
          font-weight: 800;
          font-family: $sans-serif-font;

          @include media-breakpoint-up(md) {
            font-size: 4rem;
            line-height: 4rem;
          }

          @include media-breakpoint-up(lg) {
            font-size: 6rem;
            line-height: 6rem;
          }

          > small {
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: 600;

            @include media-breakpoint-up(md) {
              font-size: 2rem;
               line-height: 2rem;
            }

            @include media-breakpoint-up(lg) {
              font-size: 3rem;
               line-height: 3rem;
            }

          }
        }
        
        > h1 {
          color: #fff;
          position: relative;
          z-index: 1;
        }
        
        > .absolute > .h1 {
          position: relative;
          z-index: 0;
          color: #000;
          text-shadow: $shadow-wrapper-h1, $shadow-wrapper-h1, $shadow-wrapper-h1;

          > small {
            font-size: 1.5rem;
            
            @include media-breakpoint-up(md) {
              font-size: 2rem;
              line-height: 2rem;
            }
          
            @include media-breakpoint-up(lg) {
              font-size: 3rem;
              line-height: 3rem;
            }

          }

        }
        
        // H2
        > h2,
        > .absolute > .h2 {
          font-size: 1.25em;
          margin-top: 1.5rem;
          line-height: 1.25rem;
          font-weight: 900;
          text-transform: none;

          @include media-breakpoint-up(md) {
            font-size: 1.75em;
          }
        }
        
        > h2 {
          color: $color__green;
          position: relative;
          z-index: 1;
        }
        
        > .absolute > .h2 {
           position: relative;
           z-index: 0;
           color: #000;
           text-shadow: $shadow-wrapper-h2, $shadow-wrapper-h2;
        }
        
        // H3
        > h3,
        > .absolute > .h3 {
          font-size: 1em;
          margin-top: 2.5rem;
          line-height: 1.25rem;
          font-weight: 700;
          text-transform: uppercase;
        }
        
        > h3 {
          color: #fff;
          position: relative;
          z-index: 1;
        }
        
        > .absolute > .h3 {
          position: relative;
          z-index: 0;
          color: #000;
          text-shadow: $shadow-wrapper-h2, $shadow-wrapper-h2;
        }
      }
      
    }
    
    .m-hero__content {
      padding-top: 30px;
      margin-bottom: -258px;

      .m-hero__links {
        margin-top: 2rem;
      }
    
      @include media-breakpoint-down(sm) {
        margin-bottom: -260px;
      }
    }
    
    // Overlays
    .hero-overlay {
      position: absolute;
            
      &.hero-overlay--left {
        bottom: 0;
        height: 30%;
        left: 0;
        content: '';
        width: 100%;
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 200% auto;
        z-index: 1;
        
        @include media-breakpoint-up(md) {
          background-size: 100% auto;
          width: 90%;
          height: 40%;
        }
      }
      
      &.hero-overlay--right {
        top: 60%;
        right: 0;
        content: '';
        background-size: contain;
        background-position: right top;
        background-repeat: no-repeat;
        z-index: 2;
        width: 25%;
        height: 40%;
        
        @include media-breakpoint-up(md) {
          top: 40%;
          width: 20%;
          height: 40%;
        }
      }
    }
  }
  
}

.m-hero--image {
	padding: 0;
}

.m-hero--image.m-hero-content-white {
    background: $color__white !important;
}

.m-hero--image .m-hero__image {
    height: 500px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    .m-breadcrumb {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    .m-breadcrumb li {
        position: relative;
        color: $color__text--reversed;
        text-shadow: none;
    }

    .m-breadcrumb li a {
        color: $color__text--reversed;
    }

    .m-hero__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .m-hero__title {
        margin-top: auto;
    }

    .m-hero__cta-banner {
        position: absolute;
        top: 35px;
        right: 0;
        left: 0;
        z-index: 10;

        ul.m-breadcrumb {
            //background-color: rgba(26, 45, 89, 0.8);
            background-color: rgba(0, 0, 0, 0.1);
            padding-left: 0.5rem;
            right: 0.5rem;
        }
    }

    .m-hero__cta-banner .a-btn {
        margin-left: 25px;
    }

    .m-hero__cta-banner h3 {
        @extend %f-h4;
        color: $color__text--reversed;
        text-shadow: -2.516px -1.634px 23px rgba(0, 0, 0, 1);
        text-transform: uppercase;
    }
}

.m-hero--image .m-hero__content {
    width: 100%;
    background: $color__secondary;
    padding: 50px 0;
    color: $color__text--reversed;

    .lead {
        line-height: 1.3125rem;
    }

    p span {
        color: $color__text--reversed !important;
    }

    p.MsoNormal{
        margin-top: 0!important;
    }
}

.m-hero--image .m-hero__title-inner {
	position: relative;
	color: $color__text--reversed;
	padding: 40px 0 30px 0;

	h1,
	h2,
	h3,
	h4,
	p {
		color: $color__text--reversed;
	}

	h1:only-child {
		margin: 15px 0;
	}

	&::before {
		content: '';
		position: absolute;
		left: -40px;
		right: -40px;
		top: 0;
		bottom: 0;
		background: $color__primary;
		border-radius: $border-radius $border-radius 0 0;
	}
}

.m-hero .m-hero__title h2 {
	@extend %f-h3;

	+ h1 {
		margin-top: 15px;
	}
}

.m-hero--image .m-hero__lift {
  @include media-breakpoint-up(lg) {
    margin-top: -160px;
    max-width: 260px;
    margin-left: auto;
  }
}

.m-hero--image {
  .m-hero__tile {
    background: $color__background;

    .a-btn {
      background: $color__primary;
      color: $color__background;
    }
  }
}


.m-hero--image.m-hero--primary {
  .f-body-lg {
    color: $color__background;
  }
  .m-hero__content {
    background: $color__variant--1;
    color: $color__separator;

    p span {
      color: $color__separator!important;
    }
  }
  .m-hero__title-inner::before {
    background: $color__primary;
  }
  .m-hero__tile {
    background: $color__background;

    .a-btn {
      background: $color__primary;
      color: $color__background;

			&:hover {
				background: darken($color__primary, 10%);
			}
    }
  }
  .m-hero__lift {
    color: $color__text--reversed;
        @include media-breakpoint-down(md) {
            color: $color__separator;
        }
  }
}

.m-hero--image.m-hero--primary-strong {
    .f-body-lg {
        color: $color__background;
    }

    .m-hero__content {
        background: $color__secondary;
        color: $color__background;

        p span {
            color: $color__background !important;
        }
    }

    .m-hero__title-inner::before {
        background: $color__primary--strong;
    }

    .m-hero__tile {
        background: $color__background;

        .a-btn {
            background: $color__primary--strong;
            color: $color__background;

            &:hover {
                background: darken($color__primary--strong, 10%);
            }
        }
    }

    .m-hero__lift {
        color: $color__text--reversed;
    }
}

.m-hero--image.m-hero--primary-light {
  .f-body-lg {
    color: $color__background;
  }
  .m-hero__content {
    background: $color__secondary;
    color: $color__background;
    p span {
      color: $color__background !important;
    }
  }
  .m-hero__title-inner::before {
    background: $color__primary--light;
  }
  .m-hero__tile {
    background: $color__background;

    .a-btn {
      background: $color__primary--light;
      color: $color__background;

			&:hover {
				background: darken($color__primary--light, 10%);
			}
    }
  }
  .m-hero__lift {
    color: $color__background;
  }
}

.m-hero--image.m-hero--secondary {
    .f-body-lg {
        color: $color__background;
    }

    .m-hero__content {
        background: $color__secondary;
        color: $color__background;

        p span {
            color: $color__background !important;
        }
    }

    .m-hero__title-inner::before {
        background: $color__secondary;
    }

    .m-hero__tile {
        background: $color__background;

        .a-btn {
            background: $color__secondary;
            color: $color__background;

            &:hover {
                background: darken($color__secondary, 10%);
            }
        }
    }

    .m-hero__lift {
        color: $color__text--reversed;
    }
}

.m-hero--image.m-hero--tertiary {
  h1, h2 {
    color: $color__secondary;
  }
  .f-body-lg {
    color: $color__secondary;
    font-weight: 900;
  }
  .m-hero__content {
    background: $color__background--dark;
    color: $color__text;
    p span {
      color: $color__text !important;
    }
  }
  .m-hero__title-inner::before {
    background: $color__tertiary;
  }
  .m-hero__tile {
    background: $color__background;

    .a-btn {
      background: $color__tertiary;
			color: $color__secondary;

			&:hover {
				background: darken($color__tertiary, 10%);
			}
    }
  }
  .m-hero__lift {
    color: $color__secondary;
  }
}

.m-hero--image.m-hero--white,
.m-hero--image.m-hero--reversed {
    h1, h2 {
        color: $color__secondary;
    }

    .f-body-lg {
        color: $color__secondary;
        font-weight: 900;
    }

    .m-hero__content {
        background: $color__secondary;
        color: $color__background;

        p span {
            color: $color__background !important;
        }
    }

    .m-hero__title-inner::before {
        background: $color__white;
    }

    .m-hero__tile {
        background: $color__background--dark;

        .a-btn {
            background: $color__secondary;
            color: $color__white;

            &:hover {
                background: darken($color__secondary, 10%);
            }
        }
    }

    .m-hero__lift {
        color: $color__secondary;

        @include media-breakpoint-down(md) {
            color: $color__background;
        }
    }
}

.m-hero--image.m-hero--variant-1 {
  h1, h2 {
    color: $color__secondary;
  }
  .f-body-lg {
    color: $color__secondary;
    font-weight: 900;
  }
  .m-hero__content {
        background: $color__secondary;
        color: $color__background;
        p span {
            color: $color__background !important;
        }
    }
  .m-hero__title-inner::before {
    background: $color__variant--1;
  }
  .m-hero__tile {
    background: $color__background--dark;

    .a-btn {
      background: $color__variant--1;
			color: $color__secondary;

			&:hover {
				background: darken($color__variant--1, 10%);
			}
    }
  }
  .m-hero__lift {
    color: $color__secondary;
    @include media-breakpoint-down(md) {
        color: $color__background;
    }
  }
}

.m-hero--image.m-hero--variant-2 {
    h1, h2 {
        color: $color__background;
    }

    .f-body-lg {
        color: $color__secondary;
        font-weight: 900;
    }

    .m-hero__content {
        background: $color__background;
        color: $color__text;

        p span {
            color: $color__text !important;
        }
    }

    .m-hero__title-inner::before {
        background: $color__variant--2;
    }

    .m-hero__tile {
        background: $color__background--dark;

        .a-btn {
            background: $color__variant--2;
            color: $color__background;

            &:hover {
                background: darken($color__variant--2, 10%);
            }
        }
    }

    .m-hero__lift {
        color: $color__background;

        @include media-breakpoint-down(md) {
            color: $color__secondary;
        }
    }
}

.m-hero--image.m-hero--variant-3 {
  h1, h2 {
    color: $color__background;
  }
  .f-body-lg {
    color: $color__secondary;
    font-weight: 900;
  }
  .m-hero__content {
    background:  $color__secondary;
    color: $color__background;
    p span {
      color: $color__background !important;
    }
  }
  .m-hero__title-inner::before {
    background: $color__online;
  }
  .m-hero__tile {
    background: $color__background--dark;

    .a-btn {
      background: $color__online;
	  color: $color__background;

	  &:hover {
		background: darken($color__online, 10%);
	  }
    }
  }
  .m-hero__lift {
    color: $color__background;
    /*@include media-breakpoint-down(md) {
        color: $color__secondary;
    }*/
  }
}

.m-hero--image.m-hero--variant-4 {
    h1, h2 {
        color: $color__secondary;
    }

    .f-body-lg {
        color: $color__secondary;
        font-weight: 900;
    }

    .m-hero__content {
        background: $color__secondary;
        color: $color__background;

        p span {
            color: $color__background !important;
        }
    }

    .m-hero__title-inner::before {
        background: $color__variant--4;
    }

    .m-hero__tile {
        background: $color__background--dark;

        .a-btn {
            background: $color__variant--4;
            color: $color__secondary;

            &:hover {
                background: darken($color__variant--4, 10%);
            }
        }
    }

    .m-hero__lift {
        color: $color__secondary;

        @include media-breakpoint-down(md) {
            color: $color__background;
        }
    }
}

.m-hero--image.m-hero--variant-5 {
  h1, h2 {
    color: $color__background;
  }
  .f-body-lg {
    color: $color__blue--very-dark;
    font-weight: 900;
  }
  .m-hero__content {
    background: $color__secondary;
    color: $color__background;
    p span {
      color: $color__background!important;
    }
  }
  .m-hero__title-inner::before {
    background: $color__variant--3;
  }
  .m-hero__tile {
    background: $color__background--dark;

    .a-btn {
      background: $color__variant--3;
			color: $color__background;

			&:hover {
				background: darken($color__variant--3, 10%);
			}
    }
  }
  .m-hero__lift {
    color: $color__background;
  }
}

.m-hero--image.m-hero--green-2 {
    h1, h2 {
        color: $color__background;
    }

    .f-body-lg {
        color: $color__green-2;
        font-weight: 900;
    }

    .m-hero__cta-banner {
    }

    .m-hero__content {
        background: $color__secondary;
        color: $color__background;

        p span {
            color: $color__background !important;
        }
    }

    .m-hero__title-inner::before {
        background: $color__green-2;
    }

    .m-hero__tile {
        background: $color__background;

        .a-btn {
            background: $color__green-2;
            color: $color__background;

            &:hover {
                background: darken($color__green-2, 10%);
            }
        }
    }

    .m-hero__lift {
        color: $color__background;
    }
}

.m-hero--image.m-hero--dark-blue-2 {
    h1, h2 {
        color: $color__background;
    }

    .f-body-lg {
        color: $color__dark-blue-2;
        font-weight: 900;
    }

    .m-hero__cta-banner {
    }

    .m-hero__content {
        background: $color__secondary;
        color: $color__background;

        p span {
            color: $color__background !important;
        }
    }

    .m-hero__title-inner::before {
        background: $color__dark-blue-2;
    }

    .m-hero__tile {
        background: $color__background;

        .a-btn {
            background: $color__dark-blue-2;
            color: $color__background;

            &:hover {
                background: darken($color__dark-blue-2, 10%);
            }
        }
    }

    .m-hero__lift {
        color: $color__background;

        /*@include media-breakpoint-down(md) {
            color: $color__separator;
        }*/
    }
}

.m-hero.m-hero--image {

    .m-hero__content-blue {
        background: $color__dark-blue-2;
        color: $color__background;

        .f-body-lg {
            color: $color__background;
        }

        p span {
            color: $color__background!important;
        }
    }

    .m-hero__content-white {
        background: $color__background--dark;
        color: $color__text;

        .f-body-lg {
            color: $color__secondary;
            font-weight: 900;
        }

        p span {
            color: $color__text!important;
        }
    }
}

.m-hero--image.m-hero--primary,
.m-hero--image.m-hero--primary-light,
.m-hero--image.m-hero--primary-strong,
.m-hero--image.m-hero--white,
.m-hero--image.m-hero--reversed,
.m-hero--image.m-hero--variant-1,
.m-hero--image.m-hero--variant-2,
.m-hero--image.m-hero--variant-3,
.m-hero--image.m-hero--variant-4,
.m-hero--image.m-hero--variant-5,
.m-hero--image.m-hero--green-2,
.m-hero--image.m-hero--dark-blue-2 {

    .m-hero__content-blue {
        .m-hero__lift {
            @include media-breakpoint-down(md) {
                color: $color__text--reversed !important;
            }
        }
    }

    .m-hero__content-white {
        .m-hero__lift {
            @include media-breakpoint-down(md) {
                color: $color__secondary !important;
            }
        }
    }
}


.m-hero--image.m-hero--primary.no-img {
    .f-body-lg {
        color: $color__secondary;
        font-weight: 900;
    }

    .m-hero__content {
        color: $color__secondary;
    }
}

.m-hero--image.no-img {
	padding: 65px 0;
}

.m-hero--image.no-img .m-hero__image {
  height: auto;
}

.m-hero--image.no-img .m-hero__content {
  background: none;
}

.m-hero__tile {
	background: $color__background;
	padding: 35px 35px;
	border-radius: $border-radius;
	color: $color__text;

	h3 + &,
	.a-text-link {
		margin-top: 20px;
	}

	span {
		@extend %f-body;
		display: block;
	}
}

.m-hero__tile h3 {
	color: $color__secondary;

	+ span {
		margin-top: 20px;
	}
}

.m-hero__tile .a-btn {
	display: block;
	margin-top: 20px;
}

.m-hero--contact h1 {
	color: $color__secondary;
	margin-bottom: 30px;
}

.m-hero--contact h2 {
	color: $color__primary;
}

.m-hero--contact .f-ui.d-inline {
	color: $color__secondary;
	margin-right: 5px;
}

.m-hero--contact .f-h3 {
	color: $color__primary;
}

.m-hero--list {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	padding: 0;
}

.m-hero--list .m-hero__image {
	width: 100%;
	position: relative;
	padding: 70px 0;
	color: $color__text--reversed;
	background: $color__primary;

	&.m-hero__image--primary {
		background: $color__primary;
	}

	&.m-hero__image--variant-1 {
		background: $color__variant--1;
	}

	&.m-hero__image--variant-2 {
		background: darken($color__variant--2, 10%);
	}

	&.m-hero__image--variant-3 {
		background: $color__variant--3;
	}

	&.m-hero__image--variant-4 {
		background: $color__variant--4;
	}

	&.m-hero__image--tertiary {
		background: $color__tertiary;
	}

	@include media-breakpoint-up(md) {
		width: 50%;
	}

	.a-media__img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.2;
		mix-blend-mode: luminosity;
		margin: 0;
	}

	.m-card {
		position: relative;
		z-index: 10;
		color: $color__text--reversed;
	}

	.a-stat,
	h3,
	p {
		color: $color__text--reversed;
	}

	h3 {
		margin-top: 10px;
	}
}

.m-hero--list {
	background: $color__background;
}

.m-hero--list .m-hero__content {
	width: 100%;

	@include media-breakpoint-up(md) {
		width: 50%;
	}
}

.m-hero--list .m-hero__list {
	width: 100%;

  .a-btn {
		position: absolute;
		right: 30px;
  }
}

.m-hero--list .m-hero__list {
	width: 100%;

  .a-btn {
		position: static;
  }
}

.m-hero--list .m-hero__list li {
	@extend %f-label-2;
	background: $color__background--med;
	padding: 25px 20px;
	position: relative;

	@include media-breakpoint-up(md) {
		padding: 30px 60px;
	}

	&:nth-child(even) {
		background: $color__background--dark;
	}

	a:not(.a-btn) {
		color: $color__secondary;

		@include media-breakpoint-up(md) {
			font-size: 18px;
	    line-height: 1.5;
		}
	}

  &:not(.m-hero__title) {
    > svg {
      margin-right: 17px;

      + a {
        position: relative;
        top: -12px;
      }
    }
  }

	.a-btn {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
	}
}

.m-hero--list .m-hero__copy {
	display: flex;
	align-items: center;
  justify-content: center;
}

.m-hero--list .m-hero__title {
	@extend %f-label-2;
	display: flex;
	flex-flow: row wrap;
	background: $color__background !important;
	position: relative;
	z-index: 11;
	align-items: center;
	padding: 25px 20px;

	@include media-breakpoint-up(md) {
		padding: 20px 25px;
		margin-left: -45px;
	}

	a:not(.a-btn) {
		color: $color__secondary;
	}

	> svg {
		margin-right: 20px;
		color: $color__placeholder;
	}

	.a-btn {
		margin-left: auto;
	}

	.m-hero__copy {
    margin-right: 35px;
	}

	.a-btn {
		display: block !important;
	}
}

.m-hero--list .m-hero__content.order-2 .m-hero__title {
	margin-left: 0;
	max-width: none;
	background: $color__background;

	@include media-breakpoint-up(md) {
		margin-right: -35px;
	}
}

.m-hero--list .m-hero__image.order-1 {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;

	h2 {
		@extend %f-h2;
		color: $color__text--reversed;
		text-transform: uppercase;
		line-height: 1.2;
		max-width: 600px;
		width: 80%;
		margin: 0 auto;
	}
}

.m-hero--list {
	.m-hero__image {
		h2 {
			@extend %f-h2;
			color: $color__text--reversed;
			text-transform: uppercase;
			line-height: 1.2;
			margin: 15px auto 0;

			+ p {
				margin-top: 10px;
			}
		}
	}

	.m-hero__list-item {
		max-width: 560px;
		width: 100%;
	}

	.m-hero__content.order-2 {
		.m-hero__title {
			.m-hero__list-item {
				> div {
					@include media-breakpoint-up(md) {
						margin-left: -35px;
					}
				}
			}
		}
	}
}

.m-hero__list-item {
  .d-flex {
    svg {
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
  }
}

.m-hero.m-hero--careers {
  padding: 0;
  background: none;

  h1 {
    @extend %f-h2;
    line-height: 1.2;
    color: $color__text--reversed;
  }

  h2 {
    @extend %f-h3;
    color: $color__primary--light;
  }

  h1 + h2 {
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }
}

.m-hero--careers {
  margin: 3px;

  p {
      color: $color__white;
  }
}

.m-hero--careers .m-hero__top {
  background: url(/dist/images/bg-blue-1.png) $color__primary no-repeat;
  background-size: cover;
  padding: 30px 0px 80px 0px;
}

.m-hero--careers .m-breadcrumb {
  padding: 0;
  margin: 0 0 90px 0;
  color: $color__text--reversed;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.m-hero--careers .m-hero__content {
  padding: 50px 0 30px 0;

  @include media-breakpoint-up(md) {
    padding: 0;
  }
}

.m-hero--careers .m-hero__image {
  border-radius: $border-radius;
  overflow: hidden;
  width: 100%;
  margin-bottom: -60px;

  @include media-breakpoint-up(md) {
    margin-bottom: -45px;
  }

  img {
    display: block;
  }
}

.m-hero__bottom {
  li {
    list-style: none !important;
    margin-left: 0 !important;
  }
}

.m-hero--careers .m-hero__bottom {
  margin-top: 80px;

  li {
    list-style: none !important;
    margin-left: 0 !important;
  }
}

.m-hero--careers .m-hero__bottom a:not(.a-btn) {
  @extend %f-body;
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: $color__text;
    position: absolute;
    z-index: 1;
    border-radius: 1px;
  }
}

.m-hero--careers .m-hero__bottom ul li {
  @extend %f-body-lg;

  + li {
    margin-top: 15px;
  }

  strong {
    color: $color__secondary;
    margin-right: 5px;
  }
}

.m-hero--careers .m-hero__bottom h3 {
  @extend %f-h4;
  color: $color__secondary;
}

.m-hero--careers .m-hero__bottom p {
  @extend %f-body-lg;

  + a {
    margin-top: 15px;
    display: block;
  }
}

.m-error-container {
  margin: 30px 0px;

  @include media-breakpoint-up(md) {
    margin: 60px 0px;
  }
}

.m-hero--careers .m-hero__bottom .text-md-right span {
  display: block;
  margin-top: 20px;

  svg {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
}

.m-hero.m-hero--application {
	margin-top: 0;
	padding: 35px 0;
	background: url('/dist/images/stats-bg.png') center center;
	background-size: cover;

	@include media-breakpoint-up(md) {
		padding: 60px 0 60px 0;
	}

	h1 {
		font-size: 36px;
		text-align: center;
		color: $color--white;
        margin-bottom: 3.2rem;

        @include media-breakpoint-up(md) {
            font-size: 62px;
            line-height: 1.25em;
        }
	}

	.m-hero__navigation {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		&_item {
			opacity: 0.5;
			display: flex;
			font-size: 2rem;
			font-weight: 900;
			max-width: 180px;
            min-width: 25%;
			text-align: center;
			align-items: center;
			color: $color--white;
			flex-direction: column;
			transition: opacity 0.2s ease-in-out;

            @include media-breakpoint-up(md) {
                font-size: 3rem;
                min-width: 0;
            }

			&:hover,
			&.is-active {
				opacity: 1;
			}

			span {
				line-height: 1.4;
				font-weight: 400;
				font-size: 0.65rem;
				margin-top: 0.6rem;
				letter-spacing: 0.6px;

                @include media-breakpoint-up(md) {
                    font-size: 0.85rem;
                    font-weight: 900;
                }
			}
		}
	}
}

.m-hero--application__intro {
	h3 {
		color: $color__text !important;
		font-weight: normal;
		font-size: 18px;
		line-height: 1.5;

		@include media-breakpoint-up(md) {
			font-size: 20px;
		}
	}
}

.m-hero.m-hero--course {
    padding: 0;
    background: none;

    h1 {
        @extend %f-h2;
        line-height: 1.2;
        color: $color__text--reversed;
    }

    h2 {
        @extend %f-h3;
        color: $color__variant--1;
    }

    h1 + h2 {
        margin-top: 10px;

        @include media-breakpoint-up(md) {
            margin-top: 25px;
        }
    }
}

.m-hero--course .m-hero__top {
    background: url(/dist/images/stats-bg.png) $color__secondary no-repeat;
    background-size: cover;
}

.m-hero--course .m-breadcrumb {
	padding: 30px 0 50px 0;
	color: $color__text--reversed;
	display: none;

	@include media-breakpoint-up(md) {
		display: block;
	}
}

.m-hero--course .m-hero__content {
	padding: 50px 0 30px 0;

	@include media-breakpoint-up(md) {
		padding: 0;
	}
}

.m-hero--course .m-hero__image {
	border-radius: $border-radius;
	overflow: hidden;
	width: 100%;
	margin-bottom: -60px;

	@include media-breakpoint-up(md) {
		margin-bottom: -45px;
	}

	img {
		display: block;
	}
}

.m-hero--course .m-hero__bottom {
	margin-top: 80px;
}

.m-hero--course .m-hero__bottom a:not(.a-btn) {
  @extend %f-body;
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: $color__text;
    position: absolute;
    z-index: 1;
    border-radius: 1px;
  }
}

.m-hero--course .m-hero__bottom ul li {
	@extend %f-body-lg;

	+ li {
		margin-top: 15px;
	}

	strong {
		color: $color__secondary;
		margin-right: 5px;
	}
}

.m-hero--course .m-hero__bottom h3 {
	@extend %f-h4;
	color: $color__secondary;
}

.m-hero--course .m-hero__bottom p {
	@extend %f-body-lg;

	+ a {
		margin-top: 15px;
		display: block;
	}
}

.m-hero--course .m-hero__bottom .text-md-right span {
	display: block;
	margin-top: 20px;

	svg {
		margin-right: 5px;
		display: inline-block;
		vertical-align: middle;
	}
}

.m-hero.m-hero--event {
	background: $color__background--med;
	padding: 55px 0;

	h2 {
		@extend %f-h2;
	}

	.m-details {
		margin-top: 20px;

		@include media-breakpoint-up(md) {
			display: flex;
			margin-top: 0;
    }

	}

	.m-details li {
		@extend %f-h4;
		color: $color__primary;
	}

	.m-details li:not(:last-child) {
		margin-right: 40px;
	}

  .m-details + .a-btn {
    margin-left: auto;
  }
}

.m-hero--facilities {
  background-size: cover;
  background-position: bottom;

  + .o-section {
    margin-top: 0;
  }
}

.m-hero--facilities + .o-section--background {
  background: $color__white;
}

.college-16-18 {
    .m-hero.m-hero--overlap h2 {
        color: $color__secondary;
    }

    .m-hero.m-hero--overlap h1 {
        color: $color__16_18;
    }

    .m-hero--search h1 span {
        color: $color__16_18;
    }

    .m-hero.m-hero--imageAndButton h2 {
        color: $color__16_18;
    }

    .m-hero.m-hero--course h2 {
        color: $color__16_18;
    }

    .m-hero__links-item {
        &::after {
            background: $color__16_18;
        }

        @include hover-supported() {
            &::after {
                max-width: 700px;
            }

            svg {
                color: $color__16_18;
            }
        }
    }

    .m-hero--contact {

        h2 {
            color: $color__16_18;
        }

        .f-h3 {
            color: $color__16_18;
        }

        .a-btn {
            background-color: $color__16_18;
            color: $color__background;

            &:hover {
                background-color: darken($color__16_18, 10%);
            }
        }
    }

    .m-hero {
        .a-btn {
            background-color: $color__16_18;
            color: $color__text;

            &:hover {
                background-color: darken($color__16_18, 10%);
            }
        }
    }

    .m-hero.m-hero--event .m-details li {
        color: $color__16_18;
    }
}

.university-centre {
  .m-hero.m-hero--overlap h2 {
    color: $color__secondary;
  }

  .m-hero.m-hero--overlap h1 {
    color: $color__variant--1;
  }

  .m-hero--search h1 span {
    color: $color__variant--1;
  }

  .m-hero.m-hero--imageAndButton h2 {
    color: $color__variant--1;
  }

  .m-hero__links-item {
    &::after {
  		background: $color__variant--1;
  	}

  	@include hover-supported() {
  		&::after {
  			max-width: 700px;
  		}

  		svg {
  			color: $color__variant--1;
  		}
  	}
  }

  .m-hero--contact {
    h2 {
      color: $color__variant--1;
    }

    .f-h3 {
      color: $color__variant--1;
    }

    .a-btn {
      background-color: $color__variant--1;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--1, 10%);
      }
    }
  }

  .m-hero {
    .a-btn {
      background-color: $color__variant--1;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--1, 10%);
      }
    }
  }

  .m-hero.m-hero--event .m-details li {
    color: $color__variant--1;
  }
}

.undergraduate {
    .m-hero.m-hero--overlap h2 {
        color: $color__secondary;
    }

    .m-hero.m-hero--overlap h1 {
        color: $color__undergraduate;
    }

    .m-hero--search h1 span {
        color: $color__undergraduate;
    }

    .m-hero.m-hero--imageAndButton h2 {
        color: $color__undergraduate;
    }

    .m-hero.m-hero--course h2 {
        color: $color__undergraduate;
    }

    .m-hero__links-item {
        &::after {
            background: $color__undergraduate;
        }

        @include hover-supported() {
            &::after {
                max-width: 700px;
            }

            svg {
                color: $color__undergraduate;
            }
        }
    }

    .m-hero--contact {
        h2 {
            color: $color__undergraduate;
        }

        .f-h3 {
            color: $color__undergraduate;
        }

        .a-btn {
            background-color: $color__undergraduate;
            color: $color__background;

            &:hover {
                background-color: darken($color__undergraduate, 10%);
            }
        }
    }

    .m-hero {
        .a-btn {
            background-color: $color__undergraduate;
            color: $color__background;

            &:hover {
                background-color: darken($color__undergraduate, 10%);
            }
        }
    }

    .m-hero.m-hero--event .m-details li {
        color: $color__undergraduate;
    }
}

.international-students {
  .m-hero.m-hero--overlap h2 {
    color: $color__secondary;
  }

  .m-hero.m-hero--overlap h1 {
    color: $color__variant--4;
  }

  .m-hero--search h1 span {
    color: $color__variant--4;
  }

  .m-hero.m-hero--imageAndButton h2 {
    color: $color__variant--4;
  }

  .m-hero__links-item {
    &::after {
  		background: $color__variant--4;
  	}

  	@include hover-supported() {
  		&::after {
  			max-width: 700px;
  		}

  		svg {
  			color: $color__variant--4;
  		}
  	}
  }

  .m-hero--contact {
    h2 {
      color: $color__variant--4;
    }

    .f-h3 {
      color: $color__variant--4;
    }

    .a-btn {
      background-color: $color__variant--4;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--4, 10%);
      }
    }
  }

  .m-hero {
    .a-btn {
      background-color: $color__variant--4;
      color: $color__background;

      &:hover {
        background-color: darken($color__variant--4, 10%);
      }
    }
  }

  .m-hero.m-hero--event .m-details li {
    color: $color__variant--4;
  }
}

.adult-19 {
    .m-hero.m-hero--overlap h2 {
        color: $color__secondary;
    }

    .m-hero.m-hero--overlap h1 {
        color: $color__adult; //$color__variant--5
    }

    .m-hero--search h1 span {
        color: $color__adult;
    }

    .m-hero.m-hero--imageAndButton h2 {
        color: $color__adult;
    }

    /*.m-hero.m-hero--course h2 {
        color: $color__adult;
    }*/

    .m-hero__links-item {
        &::after {
            background: $color__adult;
        }

        @include hover-supported() {
            &::after {
                max-width: 700px;
            }

            svg {
                color: $color__adult;
            }
        }
    }

    .m-hero--contact {
        h2 {
            color: $color__adult;
        }

        .f-h3 {
            color: $color__adult;
        }

        .a-btn {
            background-color: $color__adult;
            color: $color__white;

            &:hover {
                background-color: darken($color__adult, 10%);
            }
        }
    }

    .m-hero {
        .a-btn {
            background-color: $color__adult;
            color: $color__white;

            &:hover {
                background-color: darken($color__adult, 10%);
            }
        }
    }

    .m-hero.m-hero--event .m-details li {
        color: $color__adult;
    }
}

.apprenticeships {
    .m-hero.m-hero--overlap h2 {
        color: $color__secondary;
    }

    .m-hero.m-hero--overlap h1 {
        color: $color__apprenticeships;
    }
    
    .m-hero--search h1 span {
        color: $color__apprenticeships; //darken($color__variant--3, 10%)
    }

    .m-hero.m-hero--imageAndButton h2 {
        color: $color__apprenticeships;
    }

    .m-hero.m-hero--course h2 {
        color: $color__16_18;
    }

    .m-hero__links-item {
        &::after {
            background: $color__apprenticeships;
        }

        @include hover-supported() {
            &::after {
                max-width: 700px;
            }

            svg {
                color: $color__apprenticeships;
            }
        }
    }

    .m-hero--contact {
        h2 {
            color: $color__apprenticeships;
        }

        .f-h3 {
            color: $color__apprenticeships;
        }

        .a-btn {
            background-color: $color__apprenticeships;
            color: $color__background;

            &:hover {
                background-color: darken($color__apprenticeships, 10%);
            }
        }
    }

    .m-hero {
        .a-btn {
            background-color: $color__apprenticeships;
            color: $color__background;

            &:hover {
                background-color: darken($color__apprenticeships, 10%);
            }
        }
    }

    .m-hero.m-hero--event .m-details li {
        color: $color__apprenticeships;
    }
}

.employers {
    .m-hero.m-hero--overlap h2 {
        color: $color__secondary;
    }

    .m-hero.m-hero--overlap h1 {
        color: $color__employers;
    }

    .m-hero--search h1 span {
        color: $color__employers;
    }

    .m-hero.m-hero--imageAndButton h2 {
        color: $color__employers;
    }

    .m-hero.m-hero--course h2 {
        color: $color__employers;
    }

    .m-hero__links-item {
        &::after {
            background: $color__employers;
        }

        @include hover-supported() {
            &::after {
                max-width: 700px;
            }

            svg {
                color: $color__employers;
            }
        }
    }

    .m-hero--contact {
        h2 {
            color: $color__employers;
        }

        .f-h3 {
            color: $color__employers;
        }

        .a-btn {
            background-color: $color__employers;
            color: $color__background;

            &:hover {
                background-color: darken($color__employers, 10%);
            }
        }
    }

    .m-hero {
        .a-btn {
            background-color: $color__employers;
            color: $color__background;

            &:hover {
                background-color: darken($color__employers, 10%);
            }
        }
    }

    .m-hero.m-hero--event .m-details li {
        color: $color__employers;
    }
}

.m-hero--breadcrumb {
  position:relative;
  .m-hero__cta-banner {
    position: absolute;
    top: -7px;
    right: 0;
    left: 0;
    z-index: 10;
    white-space: nowrap;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      top: 10px;
    }
  }
}

.facilities-hero--title {
  position: absolute;
  bottom: 40px;
  padding: .5em .6em;
  background: #fff;
  border-radius: 5px;
}


.full-width-row {
  .col-white {
      color: #fff !important;
  }
  .col-text {
    color: $color__text !important;
}
}

.m-hero--vacancy .m-hero.m-hero--overlap h1 {
    color: #d83473;
    font-size: 2.125rem;
}

.m-hero--vacancy .m-hero.m-hero--overlap h2 {
    color: #fff;
}

.m-hero--vacancy .m-hero.m-hero--overlap {
    margin-bottom: 50px;
}

.m-hero--vacancy .o-textcolor--reversed {
    color: #c1d522 !important;
}

.m-hero--vacancy .o-textcolor--reversed a {
    color: #fff !important;
}
