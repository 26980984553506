.m-carousel {
    border-radius: $border-radius;
    background: $color__background--dark;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        overflow: visible;
    }

    &.m-carousel-banner {

        .carousel-item > img {
            @include media-breakpoint-down(xs) {
                object-fit: cover;
                width: 100%;
                height: 60vw;
            }
        }

        @include media-breakpoint-down(sm) {
            border-radius: 0;

            .carousel-caption {
                position: relative;
                top: auto;
                right: auto;
                bottom: auto;
                left: auto;
                transform: none;
                display: block !important;
                background: $color__secondary;
                padding-top: 75px;
                padding-bottom: 50px;

                .offset-1 {
                    margin-left: 0;
                }
            }

            .carousel-indicators {
                display: none;
            }
        }
    }

    .a-btn {
        //background-color: $color__variant--1;
    }
}

.carousel-control-desktop {
  display: none;
  @include media-breakpoint-up(sm) {
    display: inline-flex;
  }
}

.carousel-control-mobile {
  display: inline-flex;
  position: relative;
  top: 170px;
  transform: none;
  margin: 20px 5px !important;

  &.carousel-control-prev {
    margin-left: 0 !important;
  }

  @include media-breakpoint-up(sm) {
    top: auto;
  }
}

.m-carousel .carousel-control-prev, 
.m-carousel .carousel-control-next {
  opacity: 1;
  top: 43vw;//46vw;
  height: 27px;
  width: 40px;
  transform: translateY(-50%);
  margin: 0 20px;

  @include media-breakpoint-up(sm) {
    margin: 0 20px;
    top: 41vw;
    width: auto;
    bottom: auto;
  }
}
@include media-breakpoint-down(sm) {
  .carousel-control-next{
    right:auto;
    left: 45%;//50px !important;
  }
  .carousel-control-prev {
     left: auto; 
    right: 45%;
}
}

.m-carousel .carousel-control-prev:hover, 
.m-carousel .carousel-control-prev:focus, 
.m-carousel .carousel-control-next:hover, 
.m-carousel .carousel-control-next:focus {
  color: $color__secondary;
}

.m-carousel .carousel-indicators {
  bottom: 25px;
  right: 25px;
  margin: 0;
  left: auto;
  padding: 10px 6px;
  border-radius: $border-radius;
  background: $color__secondary;

  @include media-breakpoint-down(sm){
      bottom: -67px;
  }
}

.m-carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  margin: 0 4px;
  cursor: pointer;
  list-style:none;
}

.m-carousel h2 {
  @extend %f-h2;
}

.m-carousel .carousel-caption {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;
  text-align: left;

  .a-btn, p {
    margin-top: 20px;
  }
}

.m-carousel .carousel-caption--bl {
  bottom: 30px;
  left: 30px;
  top: auto;
  transform: none;
  padding: 0;
}

.m-carousel--content {
  background: none;
  margin-top: 15px;
}

.m-carousel__controls {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .carousel-control-prev, 
  .carousel-control-next,
  .carousel-indicators {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    transform: none;
  }

  .carousel-control-prev, 
  .carousel-control-next {
    margin: 0 10px;
  }

  .carousel-indicators {
    padding: 0;
    background: none;
  }

  .carousel-indicators li {
    background: rgba($color__black, 0.2);
  }

  .carousel-indicators li.active {
    background: rgba($color__black, 0.6);
  }

  .carousel-control-prev:hover, 
  .carousel-control-prev:focus, 
  .carousel-control-next:hover, 
  .carousel-control-next:focus {  
    color: $color__text--reversed;
  }
}

.carousel-mini-item {
  position: relative;
  padding: 0;
  min-height: 90px;
  }
  
  .carousel-mini-item::before {
  
  display: block;
  content: '';
  padding-top: 70%;
  width: 100%;
  box-sizing: border-box;
  }
  
  .m-carousel--mini .carousel-item img {
  /* Remove this margin:  margin: 15px 0; */
  
  /*Add the following:*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  height: auto;
  max-width: 90px;
  } 

.m-carousel--mini {
  background: none;
  border-radius: 0;
  margin-top: 20px;
  
  @include media-breakpoint-down(sm) {
    .carousel-inner {
      overflow: visible;
    }
  }
  
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .carousel-item {
    text-align: center;
    padding: 0 60px;
  
    img {
      margin: 15px 0;
      
      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  .carousel-control-prev, 
  .carousel-control-next {
    position: absolute;
    margin: 0;
    top: auto;
    bottom: -65px;
    
    &.carousel-control-next {
      left: auto !important;
      right: 0;
    }
    
    @include media-breakpoint-up(md) {
      bottom: auto;
      top: 58px;
    }
  }

  .carousel-indicators {
    position: relative;
    right: auto;
    bottom: auto;
    margin: 40px auto 0 auto;
    display: inline-flex;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    background: none;
  }

  .carousel-indicators li {
    background: rgba($color__black, 0.2);
  }

  .carousel-indicators li.active {
    background: rgba($color__black, 0.6);
  }

  .carousel-control-prev:hover, 
  .carousel-control-prev:focus, 
  .carousel-control-next:hover, 
  .carousel-control-next:focus {  
    color: $color__text--reversed;
  }
}
