.m-spacer {
  padding: 25px 0 90px;
}

.m-spacer .f-h4 {
  color: #db1cf2; // For demo purposes only
  font-weight: 500;
}

.m-spacer + .o-section {
  margin-top: 0;
}

.mobile-mt-40 {
  margin-top: 40px !important;

  @include media-breakpoint-up(sm) {
    margin-top: 75px !important;
  }
}