main {
  padding-top: 67px;

  @include media-breakpoint-up(lg) {
    padding-top: 156px;
  }

  h1, h2, h3, h4, h5, h6, p {
    &.color--primary {
      color: $color__primary;
    }

    &.color--secondary {
      color: $color__secondary;
    }
  }

  .o-section {
    &:last-child {
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        margin-bottom: 60px;
      }
    }
  }

  .m-banner.o-section:last-child {
    margin-bottom: 0;
  }

}

.college-16-18 {
  h2.color--primary {
    color: $color__primary;
  }
}

.university-centre {
  h2.color--primary {
    color: $color__variant--1;
  }
}

.international-students {
  h2.color--primary {
    color: $color__variant--4;
  }
}

.adult-19 {
  h2.color--primary {
    color: darken($color__variant--2, 10%);
  }
}

.apprenticeships {
  h2.color--primary {
    color: darken($color__variant--3, 10%);
  }
}

.employers {
  h2.color--primary {
    color: darken($color__tertiary, 10%);
  }
}


.print-only {
  display: none;
}
