.school-of-medicine {
    .o-section--grid .o-section__header h2 span {
        color: $color__online;
    }

    .o-section__caption {
        .a-btn {
            background-color: $color__online;
            color: $color__text--reversed;

            &:hover {
                background-color: darken($color__online, 10%);
            }
        }
    }

    .o-section--grid .o-section__content .o-section__links .o-section__links-item {
        .a-btn {
            background-color: $color__black--20;
            color: $color__text--reversed;

            &:hover {
                background-color: darken($color__online, 10%);
            }
        }
    }

    .o-section {
        /*.a-btn {
            background-color: $color__online;
            color: $color__text--reversed;

            &:hover {
                background-color: darken($color__online, 10%);
            }
        }*/

        .a-btn--fullscreen {
            background-color: $color__online;
            color: $color__text--reversed;

            &:hover {
                background-color: darken($color__online, 10%);
            }
        }
    }

    .o-section__header h4 {
        color: $color__online;
    }

    .o-section .m-cards__header .a-text-link,
    .o-section__header .a-text-link {
        color: $color__online;
    }

    .o-section__header h2 span {
        color: $color__online;
    }

    .o-section--overlap-top .o-section__content::before {
        background-color: $color__online;
    }

    .o-section--event h2 {
        color: $color__online;
    }
}
