// Baseline grid height
$base-height: 5;

// Breakpoint information, where each starts and stops
// needed for typography mixins
$breakpoints: (
  xsmall: (start: null, end: 575),
  small: (start: 576, end: 767),
  medium: (start: 768, end: 991),
  large: (start: 992, end: 1199),
  xlarge: (start: 1200, end: 1519),
  xxlarge: (start: 1520, end: null)
);

// Uniform border radius
$border-radius: 5px;

// Some easing functions
$bezier--bounce: cubic-bezier(.5, -.6, .5, 1.6);
$bezier--ease-in-out: cubic-bezier(.5, 0, .5, 0);


