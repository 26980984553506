.a-careers-listing {
  list-style-type: none;
  margin: 20px 0px 20px 20px;
    li {
      margin-bottom: 1rem;
      list-style: none !important;
      margin-left: 0 !important;
      line-height: 1.4;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "\2022";
        color: $color__primary;
        font-weight: bold;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
      }
    }
}

.a-skills-listing {
  list-style-type: none;
  margin: 20px 0px 20px 20px;
    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "\2022";
        color: $color__primary;
        font-weight: bold;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
      }
    }
}



.application-header--right {
  ul {
    list-style-type: none;
    margin: 0 0px 20px 20px !important;
    list-style: none;

    li {
        margin-bottom: 0.8rem;
        list-style: none !important;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
        }
        
        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: "-";
            color: $color__text;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
  }

}