html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  outline: 0;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}

html {
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  box-sizing: border-box;
  touch-action: manipulation;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

ul,
ol {
  list-style: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  touch-action: inherit;
}

.svg-sprite {
  position: absolute;
  z-index: -1;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@-ms-viewport {
  width: device-width;
}

.umbraco-forms-fieldset legend {
    padding-top: 1rem;
}
