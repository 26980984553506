// ####################################################
// Load fonts
//
// Use below @font-face declaration template to load fonts
// Copy/paste for each font to be loaded

@font-face {
  font-family: 'Biryani';
  src: url('/dist/fonts/Biryani-SemiBold.woff2') format('woff2'),
    url('/dist/fonts/Biryani-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Biryani';
  src: url('/dist/fonts/Biryani-Black.woff2') format('woff2'),
      url('/dist/fonts/Biryani-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// /* @import must be at top of file, otherwise CSS will not work */
// /* @import must be at top of file, otherwise CSS will not work */
// @import url("//hello.myfonts.net/count/3da727");
  
// @font-face {
//   font-family: "FrutigerLTPro-Bold";
//   src: url('/dist/fonts/FrutigerLTProBold/font.woff2') format('woff2'), url('/dist/fonts/FrutigerLTProBold/font.woff') format('woff');
// }
// @font-face {
//   font-family: "FrutigerLTPro-UltraBlack";
//   src: url('/dist/fonts/FrutigerLTProUltraBlack/font.woff2') format('woff2'), url('/dist/fonts/FrutigerLTProUltraBlack/font.woff') format('woff');
// }
// @font-face {
//   font-family: "FrutigerLTStd-BlackCnIta";
//   src: url('/dist/fonts/FrutigerLTStdBlackCnIta/font.woff2') format('woff2'), url('/dist/fonts/FrutigerLTStdBlackCnIta/font.woff') format('woff');
// }
// @font-face {
//   font-family: "FrutigerLTStd-Black";
//   src: url('/dist/fonts/FrutigerLTStdBlack/font.woff2') format('woff2'), url('/dist/fonts/FrutigerLTStdBlack/font.woff') format('woff');
// }
// @font-face {
//   font-family: "FrutigerLTPro-Roman";
//   src: url('/dist/fonts/FrutigerLTProRoman/font.woff2') format('woff2'), url('/dist/fonts/FrutigerLTProRoman/font.woff') format('woff');
// }

// ####################################################
// Site font stack variables

// set to 100% to use the browsers font size as a base
$global-font-size: 100% !default;

// the fall back font family string. eg. Helvetica, Arial, sans-serif.
$serif-font: serif;
$sans-serif-font: "Biryani", sans-serif;

// the font family string when the primary font is loaded (your primary font plus the fall back string)
$frutiger-900: "FrutigerLTPro-UltraBlack", sans-serif;
$frutiger-700: "FrutigerLTStd-Black", sans-serif;
$frutiger-700-italic: "FrutigerLTStd-BlackCnIta", sans-serif;
$frutiger-600: "FrutigerLTPro-Bold", sans-serif;
$frutiger-400: "FrutigerLTPro-Roman", sans-serif;

$serif-font--loaded: Georgia;
$sans-serif-font--loaded: Arial, #{$sans-serif-font};
// the appended class names when the fonts have loaded
$serif-font-loaded-class: "js-serif-font-loaded";
$sans-serif-font-loaded-class: "js-sans-serif-font-loaded";
// cap heights of the fonts - measure height of a capital H at 100px and then divide by 100
$cap-heights: (
  serif: 0.66,
  sans-serif: 0.76,
  arial: 0.72,
  helvetica: 0.72,
  "Times New Roman": 0.67,
  "Roboto": 0.72
);

// ####################################################
// Site fonts objects, mixins, placeholders and classes
//
// Sample block below. Copy, paste and edit for each type
// style you need.
// `xsmall` is basically the default
// Attributes in the settings are additive, that is, if
// you set something, font-weight, in xsmall, it value
// will be carried through all breakpoints, unless you
// supply a new font-weight in a later breakpoint (in
// which case that new value becomes the value for all
// later breakpoints).
//
// You always need to set a `xsmall`.

/***

f-XX

```
<p class="f-XX">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/
/*
$f-XX: generate-font-obj(
  (
    font-family: $serif-font,
    font-family-loaded: $serif-font--loaded,
    font-loaded-class: $serif-font-loaded-class,
    settings: (
      'xsmall': (font-size: 30, line-height: 30, letter-spacing: -0.015em, font-weight: 300, push: 0),
      'medium+': (font-size: 48, line-height: 55, push: 0),
      'xlarge+': (font-size: 60, line-height: 65, push: 0),
      'xxlarge': (font-size: 72, line-height: 75, push: 0)
    )
  )
);

@mixin f-XX() {
  @include font-styles($f-XX);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-XX,
.f-XX {
  @include f-XX;
}
*/

/***

f-h1

```
<p class="f-h1">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-h1: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 30,
        line-height: 30,
        font-weight: 900
      ),
      "medium+": (
        font-size: 50,
        line-height: 50
      )
    )
  )
);

@mixin f-h1() {
  @include font-styles($f-h1);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-h1,
.f-h1 {
  @include f-h1;
}

/***

f-h2

```
<p class="f-h2">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-h2: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 20,
        line-height: 24,
        font-weight: 900
      ),
      "medium+": (
        font-size: 30,
        line-height: 30
      )
    )
  )
);

@mixin f-h2() {
  @include font-styles($f-h2);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-h2,
.f-h2 {
  @include f-h2;
}

/***

f-h3

```
<p class="f-h3">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-h3: generate-font-obj(
  (
    font-family: $sans-serif-font,
    color: $color__text,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 20,
        line-height: 24,
        font-weight: 900
      )
    )
  )
);

@mixin f-h3() {
  @include font-styles($f-h3);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-h3,
.f-h3 {
  @include f-h3;
}

/***

f-h1-lg

```
<p class="f-h1-lg">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-h1-lg: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 35,
        line-height: 40,
        font-weight: 900
      ),
      "medium+": (
        font-size: 70,
        line-height: 70,
        font-weight: 900
      )
    )
  )
);

@mixin f-h1-lg() {
  @include font-styles($f-h1-lg);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-h1-lg,
.f-h1-lg {
  @include f-h1-lg;
}

/***

f-h4

```
<p class="f-h4">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-h4: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 15,
        line-height: 20,
        font-weight: 900
      )
    )
  )
);

@mixin f-h4() {
  @include font-styles($f-h4);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-h4,
.f-h4 {
  @include f-h4;
}

/***

f-label-1

```
<p class="f-label-1">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-label-1: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 14,
        line-height: 14,
        font-weight: 600,
        text-transform: uppercase
      )
    )
  )
);

@mixin f-label-1() {
  @include font-styles($f-label-1);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-label-1,
.f-label-1 {
  @include f-label-1;
}

/***

f-label-2

```
<p class="f-label-2">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-label-2: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 15,
        line-height: 15,
        font-weight: 900,
        text-transform: uppercase
      )
    )
  )
);

@mixin f-label-2() {
  @include font-styles($f-label-2);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-label-2,
.f-label-2 {
  @include f-label-2;
}

/***

f-ui

```
<p class="f-ui">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-ui: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 14,
        line-height: 14,
        font-weight: 600
      )
    )
  )
);

@mixin f-ui() {
  @include font-styles($f-ui);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-ui,
.f-ui {
  @include f-ui;
}

/***

f-ui-sm

```
<p class="f-ui-sm">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-ui-sm: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 12,
        line-height: 18
      )
    )
  )
);

@mixin f-ui-sm() {
  @include font-styles($f-ui-sm);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-ui-sm,
.f-ui-sm {
  @include f-ui-sm;
}

/***

f-ui-lg

```
<p class="f-ui-lg">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-ui-lg: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 15,
        line-height: 15,
        font-weight: 900
      ),
      "medium+": (
        font-size: 19,
        line-height: 20
      )
    )
  )
);

@mixin f-ui-lg() {
  @include font-styles($f-ui-lg);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-ui-lg,
.f-ui-lg {
  @include f-ui-lg;
}

/***

f-input-lg

```
<p class="f-input-lg">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-input-lg: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 15,
        line-height: 15,
        font-weight: 900,
        text-transform: uppercase
      ),
      "medium+": (
        font-size: 20,
        line-height: 20
      )
    )
  )
);

@mixin f-input-lg() {
  @include font-styles($f-input-lg);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-input-lg,
.f-input-lg {
  @include f-input-lg;
}

/***

f-body

```
<p class="f-body">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-body: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 14,
        line-height: 21,
        font-weight: 600
      )
    )
  )
);

@mixin f-body() {
  @include font-styles($f-body);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-body,
.f-body {
  @include f-body;
}

/***

f-body-lg

```
<p class="f-body-lg">The quick brown fox, <br>jumps over the lazy dog</p>
```

***/

$f-body-lg: generate-font-obj(
  (
    font-family: $sans-serif-font,
    font-family-loaded: $sans-serif-font--loaded,
    font-loaded-class: $sans-serif-font-loaded-class,
    settings: (
      "xsmall": (
        font-size: 16,
        line-height: 23,
        font-weight: 600
      )
    )
  )
);

@mixin f-body-lg() {
  @include font-styles($f-body-lg);
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums;
  font-kerning: normal;
}

%f-body-lg,
.f-body-lg {
  @include f-body-lg;
}