.m-search {
  width: 100%;
  position: relative;
}

.m-search .icon--search {
  color: $color__secondary;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 23px;
  height: 23px;

  @include media-breakpoint-up(md) {
    left: 35px;
    width: 40px;
    height: 40px;
  }
}

.icon--search-sidebar {
  display: block;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 23px;
  height: 23px;
  color: $color__black--22;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.search--sidebar-hidden {
  display: none;
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.m-search .a-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  padding: 0 10px;
  min-width: 0;

  @include media-breakpoint-up(md) {
    top: 15px;
    right: 15px;
    bottom: 15px;
    padding: 25px 30px;
  }
}

.m-search .a-btn svg {
  margin-left: 0;

  @include media-breakpoint-up(md) {
    margin-left: 14px;
  }
}

.m-search__input {
  @extend %f-input-lg;
  background: $color__background;
  padding: 15px 60px 15px 50px;
  border-radius: 6px;
  border: none;
  width: 100%;
  box-shadow: -14.333px 49.986px 49px 0px rgba(0, 0, 0, 0.11);
  line-height:normal;
  
  @include placeholder() {
    color: $color__placeholder;
    //font-style: italic;
    font-weight: 400;
  }

  @include media-breakpoint-up(md) {
    padding: 30px 265px 30px 100px;
  }

  &:active, &:focus {
    + .a-btn {
      background: $color__primary;

      &:hover {
        background: darken($color__primary, 10%);
      }
    }
  }
}

.m-search__results--no {
  h4 {
    line-height: 1.5;

    span {
      color: $color__secondary;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.btn-txt--mobile {
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}