

p {
	@extend %f-body;

	+ p {
		margin-top: 20px;
	}
}

ul li {
	list-style-type: none;
}

h1,
h2,
h3,
p,
ul,
ol,
dl {
    margin-top: 0;
}

img {
	border: 0 none;
	max-width: 100%;
}

a {
	color: inherit;
	text-decoration: none;
}

.text-padding {
	padding: 0 0 1rem 0;
}

.js-hide {
	display: none !important;
}

.js-show {
	display: block;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.border-left {
	position: relative;

	&::before {
		@include media-breakpoint-up(md) {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: -20px;
			border-left: 1px solid $color__border;
		}
	}
}

.border-bottom {

	&::after {
		left: 0;
		bottom: 0;
		width: 100%;
		content: '';
		display: block;
		margin-top: 4rem;
		position: relative;
		border-bottom: 1px solid $color__border;
	}
}

.border-bottom:first-child {

    &::after {
        content: '';
        display: none;
        margin-top: 2rem;
    }
}

.checkbox-margin {
	margin-bottom: 20px;
}

.form-margin-top {
	margin-top: 60px;
}

.img-rounded {
  border-radius: $border-radius;
  overflow: hidden;
}

.img-news-article {
  width: 100%;
}

.clr-white {
	color: $color__white !important;
}

.width-100 {
	width: 100%;
}

.gallery-btn--custom {
    width: 100%;
	display: block;
	border-radius: 5px;
	overflow: hidden;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	position: relative;
}
