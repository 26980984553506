.m-pills {
  display: flex;
}

.m-pills .a-btn {
  @extend %f-ui;
  text-transform: uppercase;
  background: $color__secondary;
  padding: 10px 15px;
  border-radius: 0;
  margin: 0 1px;
  min-width: 0;
  min-height: 20px;

  &:hover {
    background: darken($color__secondary, 10%);
  }
}

.m-pills .a-btn:first-child {
  margin-left: 0;
  border-radius: 5px 0 0 5px;
}

.m-pills .a-btn:last-child {
  margin-right: 0;
  border-radius: 0 5px 5px 0;
}
