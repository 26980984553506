.m-divider {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 2rem;
  
  > .grid-container {
    position: relative;
    z-index: 2;
    
    &:before {
      content: '';
      position: absolute;
      background-color: #eee;
      width: calc(100% - 30px);
      height: 10px;
      left: 15px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: -1;
    }
  }
  
  .a-btn--lg {
    padding: 35px 45px;
  }
  
}
