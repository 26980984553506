// @mixin float-clear
@mixin float-clear() {
  &::after {
    content: '.';
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@mixin hover-supported {    
  @media (hover: hover), (-ms-high-contrast:none) {
      &:hover {
          @content;
      }
  }
}