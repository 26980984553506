.m-map {
  padding-bottom: 100%;
  border-radius: $border-radius;
  background: $color__caption;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) { 
    padding-bottom: 40%;
  }

  .o-section & {
    margin-top: 30px;
  }
}

.m-map__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gm-style .gm-style-iw-c {
  background: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;

  button[title="Close"] {
    top: 0 !important;
    right: 0 !important;
  }
}

.gm-style .gm-style-iw-t::after {
  content: '';
  display: none;
}

.gm-style-iw-d {
  overflow: auto !important;
}

.m-map__popup-wrap {
  padding-left: 18px;
}

.m-map__popup {
  background: rgba($color__background, 0.95);
  padding: 30px 30px 30px 30px;
  border-radius: $border-radius;
  width: 255px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 18px 18px 18px 0;
    border-color: transparent rgba($color__background, 0.95) transparent transparent;
    top: 50%;
    left: 0;
    transform: translate(-17px, -18px);
  }
}

.m-map__popup h3 {
  @extend %f-label-2;
  color: $color__secondary;

  + span {
    margin-top: 20px;
  }
}

.m-map__popup span {
  @extend %f-body;
  color: $color__text;
  display: block;

  + a {
    margin-top: 25px;
  }
}