.m-owl-carousel {

    .carousel-mini-item__img {
        width: auto !important;
        max-height: 125px;
        margin-left: auto;
        margin-right: auto;
    }

}
