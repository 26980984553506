.g-header {
  background: #ededed;
  color: $color__text;
  padding: 10px 0;
  width: 100vw;
  position: fixed;
  top: 0;
  transition: top 0.5s ease-in-out;
  box-shadow: 7.981px -15.01px 49px 0px rgba(0, 0, 0, 0.18);
  overflow: hidden;
  z-index: 1300;
  transition: transform 0.4s, -webkit-transform 0.4s;

  @include media-breakpoint-up(lg) {
    //background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 16%, rgba(238,238,238,1) 48%, rgba(231,231,231,1) 100%);
    overflow: inherit;
    padding: 12px 0 70px 0;
  }
  
  @media (min-width: 1600px) {
    //background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 32%, rgba(238,238,238,1) 66%, rgba(231,231,231,1) 100%);
  }
  
  /*
  &::after {
    position: absolute;
    content: '';
    background: $color__background;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 5px;
  }
  */
}

/*
.scroll-down .g-header {
  transform: translate3d(0, -100%, 0);
}
*/

.menu-open {
  .g-header {
    overflow:visible;
  }
  &.scroll-down {
    .g-header {
      transform: none;
    }
  }
}

.g-header--salary {
  color: #ab9112 !important;
}
  
.scroll-up .g-header {
	transform: none;
}

.g-header .container-fluid {
  max-width: 1260px;
  align-items: center;
  justify-content: space-between;
}

.g-header::before {
    @include media-breakpoint-up(lg) {
        content: '';
        position: absolute;
        background: url(/dist/images/header-bg.svg) no-repeat;
        background-size: cover;
        width: 33.2%;
        height: 100%;
        right: -115px;
        top: 0;
    }

    @include media-breakpoint-up(xl) {
        right: -100px;
    }
}

.g-header .g-nav__toggle {
  margin-left: 25px;
  color: $color__text--reversed;
  position: relative;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    width: 277px;
    padding-bottom: 277px;
    background: $color__primary;
    z-index: -1;
    top: 50%;
    left: -50%;
    transform: translateY(-50%);
    border-radius: 100%;
  }
}

.g-header__logo {
  display: block;
  height: 67px;
  width: 100%;
  max-width: 190px;
  
  @include media-breakpoint-up(lg) {
    height: 74px;
    max-width: 163px;
    margin-right: 40px;
    z-index: 11;
  }
}

.g-header__logo svg {
  height: 100%;
  width: 100%;
  display: block;
}

.g-header__tel,
.nav--custom-apply {
  @extend %f-ui-lg;
  position: relative;

  &::after {
    content: "";
    left: 0;
    right: 0;
    bottom: -5px;
    height: 3px;
    background: $color__primary;
    position: absolute;
    border-radius: 1px;
    transition: all 300ms ease;
    max-width: 0;
  }

  @include hover-supported() {
    &::after {
      max-width: 700px;
    }
  }
}

.g-header__nav {
  display: flex;
  margin-top: 45px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.g-header__nav-item {
  @extend %f-ui;
  color: $color__secondary;
  margin: 0 20px 0 0;
  text-decoration: none;
  position: relative;

  @include media-breakpoint-up(lg) {
    color: $color__secondary;
    margin: 0 12px;
  }

  &::after {
    content: "";
    left: 0;
    right: 0;
    bottom: -5px;
    height: 3px;
    background: $color__secondary;
    position: absolute;
    border-radius: 1px;
    transition: all 300ms ease;
    max-width: 0;
  }

  @include hover-supported() {
    &::after {
      max-width: 700px;
    }
  }
}

.g-header__btns {
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    position: relative;
  }
}

.g-header__btns .a-btn:last-child {
  margin-left: 5px;
  
  @include media-breakpoint-up(xl+25) {
    margin-left: 22px;
  }
}

.g-header .m-pills {
  flex-direction: row;
  align-items: normal;

  @include media-breakpoint-down(md) {
    margin-bottom: 1rem;
  }
  
  span {
    padding: 10px 15px;
    font-size: 13px;
  }
  
  .a-btn {
    color: $color__text--reversed;
    font-weight: 700;
    background: $color__text--reversed;

    &.a-btn--reversed {
      color: $color__text !important;
    }
  }

  

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: normal;
  }

  .a-btn {
    @include media-breakpoint-down(md) {
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
}

.g-header__cta {
  border-radius: 0;
  margin: 10px 0 -10px 0;
  z-index: 100;
  text-align: center;
  width: 100%;
  position: relative;
  padding: 20px 0;
  justify-content: center;
  border: none;
  box-shadow: inset 0px -2px 5px 0px rgba(0,0,0,0.2);
}
