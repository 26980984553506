@mixin tools-ratio($x, $y, $debug: false) {

  &:before {
    content: '';
    display: flex;
    padding-top: ($y / $x) * 100%;
  }

  @if($debug) {

    & {
      position: relative;
    }

    &:after {
      right: 0;
      bottom: 0;
      padding: 5px;
      color: white;
      display: flex;
      position: absolute;
      background: #000000;
      content: '#{$x} / #{$y}';
    }
  }
}


$debug: false;
