.a-media__img {

  + .a-btn {
    margin-top: 15px;
  }
}

.a-media--circle {
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.university-centre {
  .a-media__img + .a-btn {
    background-color: $color__variant--1;
    color: $color__background;

    &:hover {
      background-color: darken($color__variant--1, 10%);
    }
  }
}

.undergraduate {
  .a-media__img + .a-btn {
    background-color: $color__variant--1;
    color: $color__background;

    &:hover {
      background-color: darken($color__variant--1, 10%);
    }
  }
}

.international-students {
  .a-media__img + .a-btn {
    background-color: $color__variant--4;
    color: $color__background;

    &:hover {
      background-color: darken($color__variant--4, 10%);
    }
  }
}

.international-students {
  .a-media__img + .a-btn {
    background-color: $color__variant--4;
    color: $color__background;

    &:hover {
      background-color: darken($color__variant--4, 10%);
    }
  }
}

.adult-19 {
  .a-media__img + .a-btn {
    background-color: $color__variant--2;
    color: $color__secondary;

    &:hover {
      background-color: darken($color__variant--2, 10%);
    }
  }
}

.apprenticeships {
  .a-media__img + .a-btn {
    background-color: $color__variant--3;
    color: $color__secondary;

    &:hover {
      background-color: darken($color__variant--3, 10%);
    }
  }
}

.employers {
  .a-media__img + .a-btn {
    background-color: $color__tertiary;
    color: $color__secondary;

    &:hover {
      background-color: darken($color__tertiary, 10%);
    }
  }
}

.news-img-container {
  width: 100vw;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}