.m-block--split {
  background: $color__background;
  border-radius: $border-radius;
  overflow: inherit;
  box-shadow: -14.333px 49.986px 49px 0px rgba(0, 0, 0, 0.11);
  position: relative;

  > .row {
    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  > .row > .col-12 {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
  
  &.m-block--no-shadow {
    box-shadow: none;
  }
}

.m-block--split .m-block__img {
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    position: relative;
  }
}


.m-block__img::before {
  content: '';
  position: absolute;
  top: -60px;
  left: -15px;
  bottom: 0;
  background: #ffffff;
  z-index: 1;
  box-sizing: border-box;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;

  @include media-breakpoint-up(md) {
    content: none;
  }
}


.col-img-mobile {
    padding-right: 0;
    padding-left: 0;
    margin-left: -5px;
    width: 100vw;

    @include media-breakpoint-up(sm) {
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 0;
      width: auto;
    }
}

.m-block--split .m-block__content {
    padding: 25px 25px;
    height: 100%;

    @include media-breakpoint-up(md) {
        padding: 50px 50px;
    }

    .a-btn {
        margin-top: 30px;
    }
}

.m-block__content {
  h3 {
    @extend %f-h3;
    color: $color__secondary;
  }

  h4 {
    @extend %f-h4;
    color: $color__primary;
    + h2, + h3, + h4 {
      margin-top: 5px;
    }
  }

  h2, h3, h4 {
    + p {
      margin-top: 20px;
    }
  }

  .m-form__checkbox {
    margin: 20px 0;
  }

  .m-form__checkbox label {
    font-weight: 900;
    text-align: left;
    line-height: 1.5;
  }
}


.m-block--split.m-block--reverse {
  > .row > .col-12:first-child {
    @include media-breakpoint-up(md) {
      order: 2;
    }
  }

  > .row > .col-12:last-child  {
    @include media-breakpoint-up(md) {
      order: 1;
    }
  }
}

.m-block--split.m-block--image {
  > .row {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "overlay";
    }
  }

  > .row > .col-12 {
    @include media-breakpoint-up(md) {
      grid-area: overlay;
    }
  }

  .m-block__content {
    @include media-breakpoint-up(md) {
      background: $color__white;
      border-radius: $border-radius;
      margin: 50px 0 50px 50px;
    }
  }
}

.m-block--text {
    h2, h3 {
        @extend %f-h3;
        color: $color__primary;

        + p {
            margin-top: 25px;
        }
    }

    h3 {
        @extend %f-h3;
        color: $color__secondary;
        max-width: 340px;
        margin-top: 24px;
    }

    p + .a-btn {
        margin-top: 20px;
    }

    .row > .col-md-6 + .col-md-6 {
        margin-top: 30px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}

.m-block--outline {
  border-radius: $border-radius;
  border: 1px solid $color__placeholder;
  padding: 20px;

  @include media-breakpoint-up(md) {
    padding: 50px 80px;
  }

  h2 {
    @extend %f-h2;
    color: $color__primary;
  }

  h3 {
    color: $color__secondary;
    line-height: 1.5;
  }

  h2 + h3 {
    margin-top: 20px;
  }

  .m-block__cols {
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      column-count: 2;
      column-gap: 30px;
      margin-top: 40px;
    }

    p {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }
}

.m-block--salary {
  background: $color__black--1;
  width: 100%;
  padding: 50px;
  border-radius: 10px;

  @include media-breakpoint-up(md) {
    padding: 50px 90px 50px 90px;
  }

  p {
    text-align: center;
  }

  h1 {
    text-align: center;
    font-size: 3.75rem;
    color: $color__primary;
    font-weight: bold;
    margin-bottom: 15px;
  }

  h2 {
    text-align: center;
    font-size: 1.1rem;
    color: $color__navy;
  }
}

.m-block--list {
  padding: 25px 15px 0 15px;

  @include media-breakpoint-up(md) {
    padding: 25px 0 0 0;
  }

  &.large-list {
    padding-top: 0px;
    padding: 30px 30px 30px 30px;
    border: 2px solid $color__black--20;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @include media-breakpoint-up(md) {
      padding: 45px 50px 30px 50px;
    }

    ul {
      margin: 0px 0px 20px 0px;

      @include media-breakpoint-up(md) {
        margin: 20px 0px 20px 0px;
      }

      li {
        margin-bottom: 25px;
        margin-left: 1em !important;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    h3 {
      width: 100%;
      margin-bottom: 25px;

      @include media-breakpoint-up(md) {
        margin-bottom: 15px;
      }
    }
  }
}

.m-block--article-divider {
  height: 1px;
  width: 100%;
  background: $color__black--20;
  margin-top: 50px;
}

.m-block--article-sidebar {
  @include media-breakpoint-up(sm) {
    position: sticky;
    top: 50px;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.m-block--article {
  margin-top: 50px;

  .article-text {
    h1 {
        color: $color__secondary;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 30px;
        margin-top: 30px;

        &:first-child {
          margin-top: 0;
        }
    }

    a {
      color: $color__blue;
      text-decoration: underline;
      display: inline;
      cursor: pointer;
      
      &.a-btn {
        text-decoration: none;
        display: inline-block;
        margin-top: 0 !important;
      }
    }

    h2 {
      color: $color__secondary;
      font-size: 15px;
      margin-bottom: 30px;
    }

    ul {
      list-style: initial;
      margin: 0 21px 30px 21px;

      li {
        list-style: initial;
        margin-bottom: 10px;
        font-size: 15px;
      }
    }

    p {
      font-size: 15px;
    }

    img {
      margin: 30px 0 0 0;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 75%;
      }
    }
  }
}

.col-btn-block {
  @include media-breakpoint-down(md) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
  }
}

.m-article--quote-block {
  margin-top: 30px;
  margin-left: 55px;
  padding-right: 10%;
  position: relative;
  padding-bottom: 1rem;
  line-height: 1.3rem;

  @include media-breakpoint-up(md) {
    padding-right: 25%;
    padding-bottom: 1.3rem;
  }

    p {
        font-size: 16px;
    }

  &::before {
    background-image: url(/dist/images/quote.png);
    background-size: 30px 25px;
    display: inline-block;
    width: 30px;
    height: 25px;
    position: absolute;
    left: -55px;
    content: "";
  }
}

.m-block--article-header {
  margin-bottom: 50px;

  h3 {
    color: $color__secondary;
    text-transform: unset;
  }

  h1 {
    font-size: 1.6rem;
    color: $color__secondary;
    font-weight: bold;
    margin: 25px 0;
    line-height: 1.2;
  }
}

.m-block__contact {
  p {
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin-right: 26px;
    }

  + p {
    margin-top: 20px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

    span {
      color: $color__primary;
      font-weight: 900;
    }
  }
}

.college-16-18 {
    .m-block__content h4 {
        color: $color__16_18;
    }

    .m-block--text h2, .m-block--text h3 {
        color: $color__16_18;
    }

    .m-block--outline h2 {
        color: $color__16_18;
    }

    .m-block__contact p span {
        color: $color__16_18;
    }
}

.university-centre {
  .m-block__content h4 {
    color: $color__variant--1;
  }

  .m-block--text h2, .m-block--text h3 {
    color: $color__variant--1;
  }

  .m-block--outline h2 {
    color: $color__variant--1;
  }

  .m-block__contact p span {
    color: $color__variant--1;
  }
}

.undergraduate {
  .m-block__content h4 {
    color: $color__undergraduate;
  }

  .m-block--text h2, .m-block--text h3 {
    color: $color__undergraduate;
  }

  .m-block--outline h2 {
    color: $color__undergraduate;
  }

  .m-block__contact p span {
    color: $color__undergraduate;
  }
}

.international-students {
  .m-block__content h4 {
    color: $color__variant--4;
  }

  .m-block--text h2, .m-block--text h3 {
    color: $color__variant--4;
  }

  .m-block--outline h2 {
    color: $color__variant--4;
  }

  .m-block__contact p span {
    color: $color__variant--4;
  }
}

.adult-19 {
    .m-block__content h4 {
        color: $color__adult;
    }

    .m-block--text h2, .m-block--text h3 {
        color: $color__adult;
    }

    .m-block--outline h2 {
        color: $color__adult;
    }

    .m-block__contact p span {
        color: $color__adult;
    }
}

.apprenticeships {
    .m-block__content h4 {
        color: $color__apprenticeships;
    }

    .m-block--text h2, .m-block--text h3 {
        //color: $color__apprenticeships;
    }

    .m-block--outline h2 {
        color: $color__apprenticeships;
    }

    .m-block__contact p span {
        color: $color__apprenticeships;
    }
}

.employers {
    .m-block__content h4 {
        color: $color__employers;
    }

    .m-block--text h2, .m-block--text h3 {
        //color: $color__employers;
    }

    .m-block--outline h2 {
        color: $color__employers;
    }

    .m-block__contact p span {
        color: $color__employers;
    }
}

.display-sm-up {
  display: none !important;
  @include media-breakpoint-up(sm) {
    display: block !important;
  }
}

.flex-sm-up {
    display: none !important;

    @include media-breakpoint-up(sm) {
        display: flex !important;
    }
}

.display-sm-down {
    display: block !important;

    @include media-breakpoint-up(sm) {
        display: none !important;
    }
}

.flex-sm-down {
    display: flex !important;

    @include media-breakpoint-up(sm) {
        display: none !important;
    }
}

.display-md-up {
    display: none !important;

    @include media-breakpoint-up(md) {
        display: block !important;
    }
}

.display-md-down {
  display: block;
  @include media-breakpoint-up(md) {
      display: none !important;
  }
}

.display-flex-sm-up {
  display: none !important;
  @include media-breakpoint-up(sm) {
    display: flex !important;
  }
}

.icon--phone-solid {
  width: 16px;
  height: 16px;
}

.active {
  display: block !important;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.t-0 {
  top: 0;
}

.r-0 {
  right: 0;
}

.modal--p {
  width: 90%;
}

.flex-start-rev {
  justify-content: flex-start;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
}
