.m-expand {
  width: 100%;
  border: 1.5px solid $color__placeholder;
  border-radius: 5px;
  padding: 25px 25px;
  margin-top: 15px;
  text-align: left;
  background: $color__background;
  transition: all 300ms ease;
  cursor: pointer;

  &:hover {
    background: darken($color__background, 5%);
  }

  &.collapsed {
    .m-expand-header {
      background-image: url(/dist/images/chevron--down.svg);
    }
  }
}

.m-expand--inner {
  background-color: inherit;
  width: 100%;
  border: none;
  cursor: pointer;

&.collapsed {
    .m-expand-header {
        background-image: url(/dist/images/chevron--down.svg);
        cursor: pointer;
    }
}

}

.m-expand-header {
  @extend %f-h4;
  display: flex;
  color: $color__secondary;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  background: url(/dist/images/chevron--up.svg) no-repeat right 4px;

  h4 {
    padding-right: 1rem;
  }
}

 .m-expand-header--title {
    padding-right: 1em !important;
  }


.m-expand__content {
    padding: 20px 0 0 10px;

    p a {
        position: relative;
        display: inline-block;

        &:after {
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: $color__text;
            position: absolute;
            z-index: 1;
            border-radius: 1px;
        }
    }
    // Lists
    ul, ol {
        font-size: 14px;
        margin-top: 1em;
        margin-bottom: 1em;

        li, a, p {
            font-size: 14px;
        }

        li {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
            margin-left: 20px;
            line-height: 1.5em;

            &:first-child {
                margin-top: 0;
            }

            p {
                line-height: 1.5em;
            }
        }
    }
}

.m-expand__content .a-media {
  width: 100%;
  border-radius: $border-radius;
  background: $color__caption;
  text-align: center;

  @include media-breakpoint-up(md) {
    width: calc(100% - 20px);
  }
}

.m-expand__content h4 {
  @extend %f-h4;
  color: $color__primary;
}

.m-expand__content h5,
.m-expand__content .h5 {
  font-weight: 900;
  color: $color__primary;
}

.m-expand__content span.d-block + p {
  margin-top: 25px;
  display: block;
}

.m-expand__content a {
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: -2px;
    /*height: 1px;*/
    background: $color__text;
    position: absolute;
    z-index: 1;
    border-radius: 1px;
  }
}

.m-expand__content span.d-block + a,
.m-expand__content p + a {
  margin-top: 25px;
}

.m-expand__content .col-md-4:not(:first-child) {
  margin-top: 25px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.m-expand {
  img {
    @include media-breakpoint-up(md) {
      width: 175px !important;
      margin: 0 auto;
    }
  }
}

.college-16-18 {
  .m-expand__content h4,
  .m-expand__content .h5,
  .m-expand__content h5 {
    color: $color__primary;
  }
}

.university-centre {
  .m-expand__content h4,
  .m-expand__content .h5,
  .m-expand__content h5 {
    color: $color__variant--1;
  }
}

.undergraduate {
  .m-expand__content h4,
  .m-expand__content .h5,
  .m-expand__content h5 {
    color: $color__variant--1;
  }
}

.international-students {
  .m-expand__content h4,
  .m-expand__content .h5,
  .m-expand__content h5 {
    color: $color__variant--4;
  }
}

.adult-19 {
  .m-expand__content h4,
  .m-expand__content .h5,
  .m-expand__content h5 {
    color: darken($color__variant--2, 10%);
  }
}

.apprenticeships {
  .m-expand__content h4,
  .m-expand__content .h5,
  .m-expand__content h5 {
    color: darken($color__variant--3, 10%);
  }
}

.employers {
  .m-expand__content h4,
  .m-expand__content .h5,
  .m-expand__content h5 {
    color: darken($color__tertiary, 10%);
  }
}
