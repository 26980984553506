.a-stat {
  font-size: rem-calc(119.68);
  line-height: 1;
  color: $color__primary;
  font-weight: 900;
}

.a-stat__unit {
  font-size: 0.4835em;
}

.a-stat p {
  color: $color__secondary;
}

.university-centre {
  .a-stat {
    color: $color__variant--1;
  }
}

.undergraduate {
  .a-stat {
    color: $color__variant--1;
  }
}

.international-students {
  .a-stat {
    color: $color__variant--4;
  }
}

.adult-19 {
  .a-stat {
    color: darken($color__variant--2, 10%);
  }
}

.apprenticeships {
  .a-stat {
    color: darken($color__variant--3, 10%);
  }
}

.employers {
  .a-stat {
    color: darken($color__tertiary, 10%)
  }
}
