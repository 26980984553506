.icon--arrow--left, .icon--arrow--left svg { width: 23px; height: 11px }
.icon--arrow, .icon--arrow svg { width: 23px; height: 11px }
.icon--book, .icon--book svg { width: 28px; height: 35px }
.icon--caret--down, .icon--caret--down svg { width: 16px; height: 10px }
.icon--chevron--down, .icon--chevron--down svg { width: 16px; height: 10px }
.icon--chevron--right, .icon--chevron--right svg { width: 11px; height: 18px }
.icon--chevron--up, .icon--chevron--up svg { width: 16px; height: 10px }
.icon--close, .icon--close svg { width: 8.8px; height: 12.8px }
.icon--download, .icon--download svg { width: 14px; height: 15px }
.icon--facebook, .icon--facebook svg { width: 28px; height: 28px }
.icon--fullscreen, .icon--fullscreen svg { width: 22px; height: 22px }
.icon--instagram, .icon--instagram svg { width: 29px; height: 28px }
.icon--loading, .icon--loading svg { width: 729.14px; height: 308.18px }
.icon--logo, .icon--logo svg { width: 79.3px; height: 44.45px }
.icon--mail, .icon--mail svg { width: 54px; height: 42px }
.icon--menu, .icon--menu svg { width: 30px; height: 21px }
.icon--phone-solid, .icon--phone-solid svg { width: undefinedpx; height: undefinedpx }
.icon--phone, .icon--phone svg { width: 35px; height: 59px }
.icon--play, .icon--play svg { width: 57px; height: 42px }
.icon--plus, .icon--plus svg { width: 13px; height: 14px }
.icon--print, .icon--print svg { width: 16px; height: 15px }
.icon--search-sidebar, .icon--search-sidebar svg { width: undefinedpx; height: undefinedpx }
.icon--search, .icon--search svg { width: undefinedpx; height: undefinedpx }
.icon--test, .icon--test svg { width: 60px; height: 60px }
.icon--tick, .icon--tick svg { width: 13px; height: 13px }
.icon--twitter, .icon--twitter svg { width: 29px; height: 28px }
.icon--upload, .icon--upload svg { width: 13px; height: 16px }
.icon--youtube, .icon--youtube svg { width: 22px; height: 17px }
