.modal-backdrop {
  background: rgba($color__secondary, 0.5);
}

.m-modal {
  background: rgba($color__background, 0.9);
  z-index: 1400;
}

.modal-apply {
  z-index: 1400;
  background: rgba($color__black, 0.3);
  align-items: center;

  .modal-dialog {
    transform: none !important;
    min-width: 47rem;
  }

  .modal-content {
    padding: 3rem 2rem;
    text-align: center;

    h4 {
      font-size: 1.2rem;
    }
  }
}

// .m-modal--background {
//   background: rgba($color__background, 0.9);
//   position: fixed;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1300;
//   display: none;
// }

.m-modal .close {
  border: none;
  background: none;
  padding: 0;
  color: $color__text--reversed;
  position: absolute;
  top: -45px;
  right: 0;
  font-size: rem-calc(14);
  line-height: rem-calc(14);
  cursor: pointer;
  background: $color__blue;
  z-index: 200;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
}

.m-modal .modal-dialog {
  @include media-breakpoint-up(lg) {
    max-width: 900px;
  }
}

.m-modal .modal-content {
  border-radius: $border-radius;
  // overflow: hidden;
  border: none;
}

.iframe-container {
  position: relative;
	padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  .icon--play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    display: block;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    display: block;
  }
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}

.modal-ucas, .modal-applied {
  .modal-content {
    padding: 40px 30px 20px 30px;
  }
  h4 {
    text-align:center;
  }
  p {
    text-align:center;
    font-size:12px;
    margin-top: 10px;
  }
  .a-btn {
    text-transform:uppercase;
    .fa-w-13 {
      width:13px;
    }
  }
  .row > div {
    justify-content: space-between;
    flex-direction: column;
    padding: 30px;
    
    @include media-breakpoint-up(md) {
      padding: 10px; 
    }
  }
}


.modal-levels-explained {
  .modal-content {
    padding:35px 35px 20px 35px;
    > * {
      margin-bottom:10px;
      font-size:14px;
    }

    ul {
      padding-left:21px;
      li {
        list-style: disc outside;
        margin-bottom:5px;
      }
    }
  }
}